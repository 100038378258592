import React from "react";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import dateFormat from "dateformat";
import styled from "styled-components";
import { DateTime } from "luxon";

const states = [
  ["Arizona", "AZ"],
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["Arkansas", "AR"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Hawaii", "HI"],
  ["Idaho", "ID"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["South Dakota", "SD"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Washington", "WA"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"],
];

export const getUrlParameter = (paramName) => {
  let paramValue = null;
  let params = new URLSearchParams(window.location.search);

  for (let p of params) {
    if (p[0].toLocaleLowerCase() === paramName) {
      paramValue = p[1];
    }
  }

  return paramValue;
};

// process CSV data
export const processData = (dataString, setData, setColumns) => {
  const parsed = Papa.parse(dataString, { header: true });
  const headers = parsed.meta.fields.map((item) => findColumn(item));

  for (const obj of parsed.data) {
    for (const key in obj) {
      const col = findColumn(key);
      obj[col] = obj[key];
    }

    if (obj.FIRSTAPPOINTMENTDATE) {
      let dt = getDate(obj.FIRSTAPPOINTMENTDATE);
      dt.setDate(dt.getDate() + 1);
      obj.FIRSTAPPOINTMENTDATE = dateFormat(dt, "yyyy-mm-dd");
    } else {
      obj.FIRSTAPPOINTMENTDATE = dateFormat(new Date(), "yyyy-mm-dd");
      if (headers.indexOf("FIRSTAPPOINTMENTDATE") === -1)
        headers.push("FIRSTAPPOINTMENTDATE");
    }

    if (!obj.ADDRESS && obj.STREET) {
      obj.ADDRESS = obj.STREET;
      if (!headers.includes("ADDRESS")) headers.push("ADDRESS");
    }

    const state = obj.STATE ? obj.STATE : obj.ST;
    if (state && state.length > 2) {
      const st = states.find((item) => item[0] === state);
      if (st) {
        obj.STATE = st[1];
      }
    } else {
      obj.STATE = state;
    }

    obj.FullAddress = getFullAddress(
      obj.ADDRESS ? obj.ADDRESS.split("\n")[0] : "",
      obj.STATE,
      obj.CITY,
      obj.ZIP
    );
  }

  const list = parsed.data.filter((item) => item.ADDRESS !== undefined);

  const columns = headers
    .filter(
      (item) =>
        item === "FIRSTNAME" ||
        item === "LASTNAME" ||
        item === "FULLNAME" ||
        item === "FIRSTAPPOINTMENTDATE" ||
        item === "ADDRESS" ||
        item === "CITY" ||
        item === "STATE" ||
        item === "ZIP"
    )
    .map((item) =>
      item === "ADDRESS"
        ? { name: item, selector: (row) => row[item], wrap: true, grow: 2 }
        : {
            name: item,
            selector: (row) => row[item],
            sortable: true,
          }
    );

  setData(list);
  setColumns(columns);

  return { containsDate: headers.includes("FIRSTAPPOINTMENTDATE"), data: list };
};

const findColumn = (search) => {
  let s = search
    .replace(/ /g, "")
    .toUpperCase()
    .replace("_", "")
    .replace("-", "")
    .replace(".", "")
    .replace("PATIENT", "")
    .replace("CODE", "")
    .replace("FULL", "")
    .replace("1", "");

  if (
    (s.indexOf("VISIT") !== -1 ||
      s.indexOf("APPOINTMENT") !== -1 ||
      s.indexOf("APT") !== -1 ||
      s.indexOf("APPT") !== -1) &&
    s.indexOf("NEXT") === -1 &&
    s.indexOf("LAST") === -1
  )
    return "FIRSTAPPOINTMENTDATE";

  if (
    s.indexOf("ADDRESS") !== -1 ||
    s.indexOf("ADDRES") !== -1 ||
    s.indexOf("LINE2") !== -1 ||
    s.indexOf("ADDRESS1") !== -1
  )
    return "ADDRESS";

  return s;
};

export const customTableSort = (rows, selector, direction) => {
  return rows.sort((rowA, rowB) => {
    const aField = selector(rowA);
    const bField = selector(rowB);

    let comparison = aField > bField ? 1 : aField < bField ? -1 : 0;

    try {
      comparison =
        new Date(aField) > new Date(bField)
          ? 1
          : new Date(aField) < new Date(bField)
          ? -1
          : 0;
    } catch (err) {}

    return direction === "desc" ? comparison * -1 : comparison;
  });
};

// handle file upload
export const handleFileUpload = (file, setData, setColumns, loadOrders) => {
  const reader = new FileReader();
  reader.onload = (evt) => {
    /* Parse data */
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: "binary" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    const result = processData(data.trim(), setData, setColumns);

    let i = null;
    let e = null;

    if (result.containsDate) {
      const orderedResults = result.data.sort((a, b) => {
        return (
          getDate(a.FIRSTAPPOINTMENTDATE) - getDate(b.FIRSTAPPOINTMENTDATE)
        );
      });
      /***
       *
       * Issue: Date not adjusted based on first appointment date
       * Fix: Code was setting i and e to a patient instead of a date. Adding FIRSTAPPOINTMENTDATE makes the rest of the flow works.
       *
       */
      i = orderedResults[0].FIRSTAPPOINTMENTDATE;
      e = orderedResults[orderedResults.length - 1].FIRSTAPPOINTMENTDATE;
    }
    loadOrders(i, e);
  };
  reader.readAsBinaryString(file);
};

export const csvArraysToObject = (csvArray) => {
  const cols = csvArray.shift();
  const result = [];

  for (const line of csvArray) {
    const obj = {};
    for (let i = 0; i < line.length; i++) {
      obj[cols[i]] = line[i];
    }
    result.push(obj);
  }

  return result;
};

export const getDate = (ini) => {
  try {
    if (!ini) return new Date();

    let d = DateTime.fromFormat(ini, "M/d/yy");
    if (!d.isValid) d = DateTime.fromFormat(ini, "M/d/yyyy");
    if (!d.isValid) d = DateTime.fromFormat(ini, "MM/dd/yy");
    if (!d.isValid) d = DateTime.fromFormat(ini, "MM/dd/yyyy");
    if (!d.isValid) d = DateTime.fromFormat(ini, "yyyy-MM-dd");
    if (!d.isValid) d = DateTime.fromFormat(ini, "D");
    if (!d.isValid) d = new Date();
    else d = d.toJSDate();

    return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
  } catch (err) {
    return new Date();
  }
};

export function monthDiff(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  // edit: increment months if d2 comes later in its month than d1 in its month
  if (d2.getDate() >= d1.getDate()) months++;
  // end edit
  return months <= 0 ? 0 : months;
}

export function dayDiff(d1, d2) {
  let Difference_In_Time = d1.getTime() - d2.getTime();
  // To calculate the no. of days between two dates
  return Difference_In_Time / (1000 * 3600 * 24);
}

export const upperCaseText = (t) => {
  return t ? t.toString().toUpperCase() : "";
};

export const getFullAddress = (address, st, city, zip) => {
  let r =
    upperCaseText(address) +
    " " +
    //upperCaseText(city) +
    //" " +
    // ------->>>>> Commenting City from this code so only Address, State and Zip are returned,
    // and city is no part of the comparison to increase potential matches.
    upperCaseText(st) +
    " " +
    zip;

  return r
    .replace(" WEST ", " W ")
    .replace(" NORTH ", " N ")
    .replace(" EAST ", " E ")
    .replace(" SOUTH ", " S ")
    .replace(" AVENUE ", " AVE ")
    .replace(" BOULEVARD ", " BLVD ")
    .replace(" #", " APT ")
    .replace(" UNIT ", " APT ")
    .replace(" DRIVE ", " DR ")
    .replace(" COURT ", " CT ")
    .replace(" LANE ", " LN ")
    .replace(" ROAD ", " RD ")
    .replace(" UNIT ", " APT ")
    .replace(" STREET ", " ST ")
    .replace(".", "")
    .replace(",", "")
    .replace("  ", " ");
};

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
  </>
);
