import React, { createContext } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

export const UserContext = createContext(null);

ReactDOM.render(
  <React.StrictMode>
    <UserContext.Provider value={{ accessToken: null }}>
      <App />
    </UserContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
