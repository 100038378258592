import "./App.css";

import React, { useContext, useState } from "react";
import { Container, Card, Button, Alert } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DatePicker from "react-date-picker";

import {
  getUrlParameter,
  handleFileUpload,
  csvArraysToObject,
  customTableSort,
  getDate,
  FilterComponent,
  getFullAddress,
  dayDiff,
} from "./components/Utils";
import {
  sfListOrders,
  sfGetCSVFromDropbox,
  sfSavePatients,
  sfListExistingPatients,
} from "./components/API";
import { readString } from "react-papaparse";
import fuzzysort from "fuzzysort";

import Loading from "./loading.gif";

import { UserContext } from "./index";

function App() {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const lastMonth = new Date();
  lastMonth.setMonth(new Date().getMonth() - 1);
  const [iniDate, setIniDate] = useState(
    new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [lockDates, setLockDates] = useState(false);
  const [mailingListAddresses, setMailingListAddresses] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [ordersProcessed, setOrdersProcessed] = useState(0);

  const [daysToCheck, setDaysToCheck] = useState(60);

  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

  const context = useContext(UserContext);

  const accountId = getUrlParameter("accountid");
  const loadOrders = async (ini, end) => {
    setIsLoadingOrders(true);
    let i = iniDate;
    let e = endDate;

    /******
     *
     * If Dates are not locked and ini and end are set (if ini, if end),
     * i = 3 months before first appointment date from CSV
     * e = last date of first appointment date form CSV
     *
     *****/
    if (!lockDates) {
      if (ini) {
        i = getDate(ini);
        i.setMonth(i.getMonth() - 3);
        setIniDate(i);
      }
      if (end) {
        e = getDate(end);
        setEndDate(e);
      }
    }

    try {
      // Pre-loads orders
      setOrdersProcessed(0);
      sfListOrders(context, accountId, i, e)
        .then((data) => {
          if (data) setOrderList(data);
          setIsLoadingOrders(false);
        })
        .catch((err) => {
          setIsLoadingOrders(false);
        });
    } catch (err) {
      setIsLoadingOrders(false);
    }
  };

  const searchPatientsInMailingList = () => {
    setIsProcessing(true);
    fetchMailingLists();
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const fetchMailingLists = async () => {
    let mlArray = [];

    for (const o of orderList) {
      setOrdersProcessed((prev) => prev + 1);
      if (o.Dropbox_Folder__c) {
        const mlCsv = await sfGetCSVFromDropbox(context, o.Dropbox_Folder__c);
        if (mlCsv) {
          const ml = csvArraysToObject(readString(mlCsv).data);
          for (const r of ml) {
            r.order = o;
            r.FullAddress = getFullAddress(
              r.addressl
                ? r.addressl
                : r.address1
                ? r.address1
                : r.ADDRESS
                ? r.ADDRESS
                : r.ADDRES
                ? r.ADDRES
                : r.ADDRESS1
                ? r.ADDRESS1
                : r.address
                ? r.address
                : r.LINE2
                ? r.LINE2
                : r.line2
                ? r.line2
                : r.Addres
                ? r.Addres
                : r.Address,
              r.st ? r.st : r.state ? r.state : r.STATE,
              r.city ? r.city : r.CITY,
              r.zip ? r.zip : r.ZIP
            );
          }
          mlArray = [...mlArray, ...ml];
        }
        delay(1000);
      }
    }

    setMailingListAddresses(mlArray);

    ////// Fuzzy match each Patient:::::::
    mlArray = data.filter((patient) => {
      let filtered = false;
      const dtPatient = patient.FIRSTAPPOINTMENTDATE
        ? getDate(patient.FIRSTAPPOINTMENTDATE)
        : new Date();

      if (dtPatient < iniDate) return false;

      if (
        patient.FullAddress !== undefined &&
        patient.FullAddress !== "undefined"
      ) {
        const results = fuzzysort.go(patient.FullAddress, mlArray, {
          key: "FullAddress",
          limit: Infinity,
          threshold: -Infinity,
        });

        const found = [];
        for (const result of results) {
          let tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);

          const dtOrder = result.obj.order.EffectiveDate
            ? getDate(result.obj.order.EffectiveDate)
            : tomorrow;

          const mdiff = dayDiff(dtPatient, dtOrder); ////// LATEST DATE FIRST
          if (dtPatient > dtOrder && mdiff <= daysToCheck) {
            // If there is already a value in found, check if the date is AFTER
            // ------> We try to match to the LATEST Order
            if (found.length === 0) {
              patient.dtOrderDate = dtOrder;
              patient.OrderId = result.obj.order.Id;
              patient.OrderLabel = result.obj.order.Order_Label__c;
              patient.OrderDate = result.obj.order.EffectiveDate
                ? new Date(result.obj.order.EffectiveDate)
                    .toJSON()
                    .split("T")[0]
                : "";
              patient.PatientDate = patient.FIRSTAPPOINTMENTDATE
                ? new Date(patient.FIRSTAPPOINTMENTDATE).toJSON().split("T")[0]
                : "";
              patient.MailingListFullAddress = result.obj.FullAddress;
              if (patient.FIRSTNAME || patient.LASTNAME)
                patient.PatientName =
                  patient.FIRSTNAME + " " + patient.LASTNAME;
              else patient.PatientName = patient.FULLNAME;
              patient.AccountId = accountId;

              filtered = true;
              found.push(patient);
            }
            if (
              found.find((item) => item.dtOrderDate < dtOrder) !== undefined
            ) {
              const foundP = found.find((item) => item.dtOrderDate < dtOrder);
              foundP.MailingListFullAddress = result.obj.FullAddress;
              foundP.dtOrderDate = dtOrder;
              foundP.OrderId = result.obj.order.Id;
              foundP.OrderLabel = result.obj.order.Order_Label__c;
              foundP.OrderDate = result.obj.order.EffectiveDate
                ? new Date(result.obj.order.EffectiveDate)
                    .toJSON()
                    .split("T")[0]
                : "";
            }
          }
        }
      }

      return filtered;
    });

    setColumns((prev) => [
      {
        name: "Patient Name",
        selector: (row) => row.PatientName,
        sortable: true,
      },
      {
        name: "Visit Date",
        selector: (row) => row.PatientDate,
        sortable: true,
      },
      {
        name: "Patient Address",
        selector: (row) => row.FullAddress,
        wrap: true,
        grow: 2,
      },
      {
        name: "Mailing List Address",
        selector: (row) => row.MailingListFullAddress,
        wrap: true,
        grow: 2,
      },
      {
        name: "Order Label",
        selector: (row) => row.OrderLabel,
        sortable: true,
      },
      {
        name: "Order Date",
        selector: (row) => row.OrderDate,
        sortable: true,
      },
    ]);

    setIsReadyToSubmit(true);
    setData(mlArray);
    setIsProcessing(false);
    setOrdersProcessed(0);
  };

  const createPatientsInSF = async () => {
    if (data) {
      const existingPatients = await sfListExistingPatients(context, accountId);
      console.log(existingPatients);

      const patientsToCreate = [];
      for (const mp of data) {
        const existing = existingPatients.filter(
          (item) =>
            item.First_Name__c + " " + item.Last_Name__c ===
            mp.FIRSTNAME + " " + mp.LASTNAME
        );
        console.log(existing);
        if (existing.length > 0) {
          mp.Id = existing[0].Id;
        } else {
          mp.Id = null;
        }
        patientsToCreate.push(mp);
      }

      const response = await sfSavePatients(context, patientsToCreate);
      if (response.code === "200") {
        alert("Patients created / updated successfully!");
        window.location.reload();
      } else {
        alert(response.error);
      }
    }
  };

  const filteredMailingListAddresses = mailingListAddresses.filter(
    (item) =>
      item.FullAddress &&
      item.FullAddress.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Container fluid>
      <Card>
        <button
          onClick={(e) => window.location.reload()}
          style={{
            padding: "10px 40px",
            background: "blue",
            color: "white",
            borderRadius: "5px",
            width: "200px",
            margin: "15px",
            float: "right",
            border: "0",
          }}
        >
          Reload Page
        </button>
        <Card.Header>Import Patients:</Card.Header>
        <Card.Body>
          <Alert variant="warning">
            Select a CSV file with the next required fields: First Name, Last
            Name, Address, State, City, Zip. The "First Visit Date" is not
            mandatory but recommended to automatically filter data.
          </Alert>
          <input
            type="file"
            accept=".csv,.xlsx,.xls"
            onChange={(e) =>
              handleFileUpload(
                e.target.files[0],
                setData,
                setColumns,
                loadOrders
              )
            }
          />
          <br />
          <br />
          <Alert variant="warning">
            Select a date range to filter orders.{" "}
            <b>
              If your CSV file contains a field called "First Visit Date", you
              can skip this step.
            </b>
          </Alert>
          <DatePicker
            value={iniDate}
            onChange={setIniDate}
            format="yyyy-MM-dd"
          />
          <DatePicker
            value={endDate}
            onChange={setEndDate}
            format="yyyy-MM-dd"
          />
          <input
            type="checkbox"
            value={lockDates}
            onChange={(e) => setLockDates(e.target.checked)}
          />{" "}
          <span style={{ color: "gray", fontStyle: "italic" }}>
            Lock dates to avoid auto-populate from CSV file
          </span>
          <br />
          <input
            value={daysToCheck}
            onChange={(e) => setDaysToCheck(+e.target.value)}
            type="numeric"
            style={{ width: "167px", marginTop: "10px", marginBottom: "10px" }}
          />
          <span
            style={{ color: "gray", fontStyle: "italic", marginLeft: "10px" }}
          >
            Tolerance between Order Date and First Visit Date? In Days
          </span>
          <br />
          {data.length > 0 && isLoadingOrders && (
            <>
              <br />
              <Alert variant="primary">Loading orders...</Alert>
            </>
          )}
          {data.length > 0 &&
            orderList.length > 0 &&
            !isLoadingOrders &&
            !isReadyToSubmit && (
              <div className="process">
                <Button variant="primary" onClick={searchPatientsInMailingList}>
                  Process Patient List
                </Button>
                <br />
                <i>
                  * When you press Process Patient List, the system will fetch
                  CSV files from each Dropbox folder and compare each address.{" "}
                  <b>This can take some minutes to complete.</b>
                </i>
              </div>
            )}
          {data.length > 0 &&
            orderList.length > 0 &&
            !isLoadingOrders &&
            isReadyToSubmit && (
              <div className="process">
                <Button variant="success" onClick={createPatientsInSF}>
                  Create Patients in Salesforce
                </Button>
              </div>
            )}
          {!isProcessing && data.length > 0 && (
            <DataTable
              pagination
              highlightOnHover
              columns={columns}
              data={data}
              sortFunction={customTableSort}
            />
          )}
          {isProcessing && (
            <Alert variant="info">
              The info is being processed.{" "}
              <b>
                This process can take several minutes... Do not close this
                tab... <img src={Loading} alt="Processing..." height="48px" />
              </b>
              <br />
              <b>
                Downloading Mailing Lists from Dropbox...{" "}
                <span>{ordersProcessed}</span>
              </b>
            </Alert>
          )}
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          Patients will be checked against the next Dropbox Folders:
        </Card.Header>
        <Card.Body>
          {orderList !== undefined &&
            orderList
              .filter(
                (order) =>
                  !order.Order_Label__c.startsWith("GM-") &&
                  !order.Order_Label__c.startsWith("MI-") &&
                  order.Dropbox_Folder__c
              )
              .map((order) => {
                return (
                  <a
                    href={decodeURI(order.Dropbox_Folder__c)}
                    target="_blank"
                    rel="noreferrer"
                    className="dropbox-folder"
                    key={order.Id}
                  >
                    {order.EffectiveDate} - {order.Order_Label__c}
                  </a>
                );
              })}
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>All Mailing List Addresses</Card.Header>
        <Card.Body>
          {!isProcessing && mailingListAddresses.length > 0 && (
            <DataTable
              pagination
              highlightOnHover
              columns={[
                {
                  name: "Mailing List Address",
                  selector: (row) => row.FullAddress,
                  wrap: true,
                  grow: 2,
                },
                {
                  name: "Order Label",
                  selector: (row) => row.order.Order_Label__c,
                  sortable: true,
                },
              ]}
              data={filteredMailingListAddresses}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              paginationResetDefaultPage={resetPaginationToggle}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default App;
