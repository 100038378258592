import axios from "axios";

const serverUrl = "https://rest-api.dentalmarketing.net/access/WebAPI/";
const devServerUrl = "https://localhost:44302/access/WebAPI/";
const isDev = false;

const authorize = async (context) => {
  return new Promise((resolve, reject) => {
    const sParams = {
      requestKey: "4d3d57e1-1d9b-4f4b-9f64-16574f211c27",
      action: "authorize",
    };

    try {
      if (!context.AUTH_CODE) {
        axios
          .post(isDev ? devServerUrl : serverUrl, sParams)
          .then((response) => {
            if (response) {
              context.AUTH_CODE = response.data.authorization;
              resolve(context.AUTH_CODE);
            }
          })
          .catch((error) => {
            context.AUTH_CODE = "";
            reject(context.AUTH_CODE);
          });
      } else {
        resolve(context.AUTH_CODE);
      }
    } catch (err) {
      context.AUTH_CODE = "";
      reject(context.AUTH_CODE);
    }
  });
};

export const sfListOrders = async (context, accountId, ini, end) => {
  const AUTH_CODE = await authorize(context); // Requests an authorization code before making actual calls

  if (context.SF_TOKEN) {
    const renewToken = (new Date() - context.SF_TOKEN_TIME) / 3600000;
    if (renewToken >= 2) {
      context.SF_TOKEN = undefined;
      context.SF_URL = undefined;
    }
  }

  /*****
   *
   * Query Orders from Salesforce
   * Adjust line RecordType.Name to filter orders by type
   *
   *******/
  const sParams = {
    requestKey: "4d3d57e1-1d9b-4f4b-9f64-16574f211c27",
    requestCode: AUTH_CODE,
    action: "sf-search",
    token: context.SF_TOKEN,
    url: context.SF_URL,
    search: [
      {
        sobject: "Order",
        fields: "Id,EffectiveDate,Order_Label__c,Dropbox_Folder__c",
        filter: `AccountId='${accountId}' 
                AND EffectiveDate >= ${ini.toJSON().split("T")[0]}
                AND EffectiveDate <= ${end.toJSON().split("T")[0]}
                AND RecordType.Name IN ('Postcard Mailing', 'New Move-In', 'Internal Marketing Cards', 'DW')
                ORDER BY EffectiveDate DESC`,
      },
    ],
  };

  try {
    const { data } = await axios.post(
      isDev ? devServerUrl : serverUrl,
      sParams
    );

    const response = data;
    if (response.token) {
      context.SF_TOKEN = response.token;
      context.SF_URL = response.url;
      context.SF_TOKEN_TIME = new Date();
    }
    return response.Order;
  } catch (err) {
    return null;
  }
};

export const sfListExistingPatients = async (context, accountId) => {
  const AUTH_CODE = await authorize(context); // Requests an authorization code before making actual calls

  const sParams = {
    requestKey: "4d3d57e1-1d9b-4f4b-9f64-16574f211c27",
    requestCode: AUTH_CODE,
    action: "sf-search",
    search: [
      {
        sobject: "Patient__c",
        fields: "Id,First_Name__c,Last_Name__c,Source__c",
        filter: `Account__c='${accountId}' AND Source__c='Export'
                ORDER BY CreatedDate ASC`,
      },
    ],
  };

  try {
    const { data } = await axios.post(serverUrl, sParams);
    const response = data;
    return response.Patient__c;
  } catch (err) {
    return null;
  }
};

export const sfSavePatients = async (context, patients) => {
  const AUTH_CODE = await authorize(context); // Requests an authorization code before making actual calls

  if (context.SF_TOKEN) {
    const renewToken = (new Date() - context.SF_TOKEN_TIME) / 3600000;
    if (renewToken >= 2) {
      context.SF_TOKEN = undefined;
      context.SF_URL = undefined;
    }
  }

  const sfPatients = [];
  const toUpdatesfPatients = [];
  for (const p of patients) {
    const pInfo = {
      Id: p.Id ? p.Id : null,
      Name:
        p.FIRSTNAME || p.LASTNAME ? p.FIRSTNAME + " " + p.LASTNAME : p.FULLNAME,
      First_Name__c: p.FIRSTNAME,
      Last_Name__c: p.LASTNAME,
      Account__c: p.AccountId,
      Most_Recent_Order__c: p.OrderId,
      Last_Seen_Date__c: p.PatientDate,
      First_Visit_Date__c: p.PatientDate,
      Street_Address__c: p.ADDRESS,
      State__c: p.STATE,
      City__c: p.CITY,
      Zip_Code__c: p.ZIP,
      created_by_cruncher__c: true,
      Export_Date__c: new Date(),
      From_Patient_Export__c: true,
      Patient_Status__c: "Valid Patient",
      Source__c: "Export",
    };

    if (!pInfo.Id) sfPatients.push(pInfo);
    else toUpdatesfPatients.push(pInfo);
  }

  const b = {
    Request: "Create",
    Patients: sfPatients,
    ToUpdatePatients: toUpdatesfPatients,
  };
  const sParams = {
    requestKey: "4d3d57e1-1d9b-4f4b-9f64-16574f211c27",
    requestCode: AUTH_CODE,
    action: "sf-rest",
    token: context.SF_TOKEN,
    url: context.SF_URL,
    rest: [
      {
        actionName: "Create Patients",
        serviceName: "ImportPatients",
        body: JSON.stringify(b),
      },
    ],
  };

  try {
    const { data } = await axios.post(
      isDev ? devServerUrl : serverUrl,
      sParams
    );
    console.log(data);

    const response = data;
    if (response.token) {
      context.SF_TOKEN = response.token;
      context.SF_URL = response.url;
      context.SF_TOKEN_TIME = new Date();
    }
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const sfGetCSVFromDropbox = async (context, folder) => {
  const AUTH_CODE = await authorize(context); // Requests an authorization code before making actual calls
  const sParams = {
    requestKey: "4d3d57e1-1d9b-4f4b-9f64-16574f211c27",
    requestCode: AUTH_CODE,
    action: "dropbox-search",
    folders: [
      {
        folder: decodeURIComponent(
          folder
            .replace("https://www.dropbox.com/home", "")
            .replace("https://www.dropbox.com/work/Client Services", "")
            .replace("https://www.dropbox.com/work/Client%20Services", "")
        ),
        fileType: ".csv",
      },
    ],
  };

  const { data } = await axios.post(isDev ? devServerUrl : serverUrl, sParams);
  const response = data;
  return response["file-blob"] ? response["file-blob"].toString() : null;
};
